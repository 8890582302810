import React, { useState, useEffect } from 'react';
import { NavLink, useHistory, useLocation } from 'react-router-dom';
import './Navbar.css';
import LaunchLogo from '../../assets/Images/launch1logo.png';
import TwitterIcon from '@material-ui/icons/Twitter';
import TelegramIcon from '@material-ui/icons/Telegram';
import MenuIcon from '@material-ui/icons/Menu';
import MediumIcon from '../../assets/Images/medium.svg';
import Button from '@material-ui/core/Button';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import { makeStyles } from '@material-ui/core/styles';
import BSCWalletIcon from '../../assets/Images/bsc-wallet.png';
import PolygonIcon from '../../assets/Images/polygon-wallet.png';
import Ethereum from '../../assets/Images/ethereum.png';
import OnePagerPdf from '../../assets/pdf/OnePager.pdf';
import { connectMetamask, updateRouter } from '../../store/actions/actions';
import { connect } from 'react-redux';

const Navbar = (props) => {
    const useStyles = makeStyles((theme) => ({
        root: {
            display: 'flex',
        },
        paper: {
            marginRight: theme.spacing(2),
        },
    }));

    // window.addEventListener("scroll", function () {
    //     const nav = document.getElementsByClassName("navbar")[0];
    //     if (window.scrollY > 0) {
    //         nav.style.backgroundColor = "#194089";
    //     } else {
    //         nav.style.backgroundColor = "#102E6500";
    //     }
    // });

    const expandMenu = () => {
        const items = document.getElementsByClassName("small-nav-ul")[0];
        const samllnav = document.getElementsByClassName("small-nav")[0];

        if (items.style.maxHeight == "20rem") {
            samllnav.style.paddingTop = "0rem";
            items.style.maxHeight = "0rem";
        } else {
            samllnav.style.paddingTop = "4rem";
            items.style.maxHeight = "20rem";
        }
    }

    const classes = useStyles();

    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef(null);

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }

        setOpen(false);
    };

    function handleListKeyDown(event) {
        if (event.key === 'Tab') {
            event.preventDefault();
            setOpen(false);
        }
    }

    // return focus to the button when we transitioned from !open -> open
    const prevOpen = React.useRef(open);
    React.useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }
        prevOpen.current = open;
    }, [open]);

    const [hide, setHide] = useState(true)
    useEffect(() => {
        window.location.pathname === '/' ? props.updateRouter({ isAllowed: false }) : props.updateRouter({ isAllowed: true });
    }, [])

    return (
        <>
            <div className='navbar'>
                <div className='navbar-cont'>
                    <div className="navbar-left">
                        <NavLink to="/" onClick={() => props.updateRouter({ isAllowed: false })}>
                            <img src={LaunchLogo} alt="logo" id="logo" />
                        </NavLink>
                    </div>
                    <div className="navbar-right heading-4">
                       <div className='mainoldmenu'>
                       <div className="menu-item">
                            <NavLink to="/launch-app" onClick={() => props.updateRouter({ isAllowed: true })} className="menu-link" >Launch App</NavLink>
                        </div>
                        {/* <div className="menu-item">
                            <a href='https://staking.multipad.co/' target="_blank" onClick={() => props.updateRouter({ isAllowed: false })} className="menu-link">Staking</a>
                        </div> */}
                        <div className="menu-item">
                            <a href='#tiers' onClick={() => props.updateRouter({ isAllowed: false })} className="menu-link">Tiers</a>
                        </div>
                        {/* <div className="menu-item">
                            <a href="https://docs.google.com/forms/d/e/1FAIpQLSerz7SIGT-XG91WMBI1-bVpmouiBVNt0B1iW8z1VdLkIxRrHQ/viewform" className="menu-link" target="_blank" rel="noreferrer">Launch Your Product</a>
                        </div> */}
                        <div className="menu-item">
                            <a className="menu-link" href="https://multipad.gitbook.io/multipad/" target="_blank" rel="noreferrer">Documentation</a>
                        </div>
                        <div className="menu-item">
                            <a href={OnePagerPdf} className="menu-link" target="_blank" rel="noreferrer">One Pager</a>
                        </div>
                        {
                            props.router.isAllowed && hide
                                ? <div className="menu-item">
                                    <div className={classes.root}>
                                        <Button
                                            ref={anchorRef}
                                            aria-controls={open ? 'menu-list-grow' : undefined}
                                            aria-haspopup="true"
                                            onClick={handleToggle}
                                            // className="menu-link"
                                            style={{ color: 'white' }}
                                        >
                                            {
                                                props.connection.isConnected
                                                    ?
                                                    <>{props.accoutDetail.address.slice(0, 6)}....{props.accoutDetail.address.slice(-4)}
                                                        <br></br>
                                                        <span className="networkName">{props.connection.connectedNetwork.name}</span>
                                                    </>
                                                    : <>
                                                        {/* <img src={WalletIcon} alt="walletImg"></img> */}
                                                        Connect Wallet</>
                                            }
                                        </Button>
                                        <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
                                            {({ TransitionProps, placement }) => (
                                                <Grow
                                                    {...TransitionProps}
                                                    style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                                                >
                                                    <Paper>
                                                        <ClickAwayListener onClickAway={handleClose}>
                                                            <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                                                                <MenuItem
                                                                    onClick={() => { handleClose; props.onConnectToMetamask(props.networks.main.bsc, '') }}>
                                                                    <img src={BSCWalletIcon} width="25px" style={{ marginRight: '12px' }}></img> BSC Wallet
                                                                </MenuItem>
                                                                <MenuItem
                                                                    onClick={() => { handleClose; props.onConnectToMetamask(props.networks.main.ethereum, '') }}>
                                                                    <img src={Ethereum} width="25px" style={{ marginRight: '12px' }}></img> Ethereum
                                                                </MenuItem>
                                                                <MenuItem onClick={() => { handleClose; props.onConnectToMetamask(props.networks.main.polygon, '') }}>
                                                                    <img src={PolygonIcon} width="25px" style={{ marginRight: '12px' }}></img> Polygon
                                                                </MenuItem>
                                                                {/* <MenuItem
                                                                onClick={() => { handleClose; props.onConnectToMetamask(props.networks.main.avalanche, '') }}>
                                                                <img src={AvalancheIcon} width="25px" style={{ marginRight: '12px' }}></img> Avalanche
                                                            </MenuItem> */}
                                                            </MenuList>
                                                        </ClickAwayListener>
                                                    </Paper>
                                                </Grow>
                                            )}
                                        </Popper>
                                    </div>
                                </div>
                                : null
                        }
                       </div>
                       <div className='moreButtons'>
                            {/* <NavLink to="/launch-app" onClick={() => props.updateRouter({ isAllowed: true })} className="btn2">Launchpad</NavLink>                     */}
                            <a href='https://staking.multipad.co/' target="_blank" onClick={() => props.updateRouter({ isAllowed: false })} className="btn2">Staking</a>
                       </div>
                        <div className="bar-menu" onClick={expandMenu}>
                            <MenuIcon />
                        </div>
                    </div>
                </div>
            </div>
            <div className="small-nav">
                <ul className="small-nav-ul">
                    <br />
                    <li style={{ cursor: 'pointer' }} className="small-nav-li">
                        <NavLink to="/launch-app" className="menu-link">Launch App</NavLink>
                    </li>
                    <li style={{ cursor: 'pointer' }} className="small-nav-li">
                        <a href='https://staking.multipad.co/' target="_blank" className="menu-link">Staking</a>
                    </li>
                    <li style={{ cursor: 'pointer' }} className="small-nav-li">
                        <a href='#tiers' className="menu-link">Tiers</a>
                    </li>
                    <li style={{ cursor: 'pointer' }} className="small-nav-li">
                        <NavLink to="/" className="menu-link" >Launch Your Product</NavLink>
                    </li>
                    <li style={{ cursor: 'pointer' }} className="small-nav-li">
                        <a className="menu-link" href="https://multipad.gitbook.io/multipad/" target="_blank" rel="noreferrer">Documentation</a>
                    </li>
                    <li style={{ cursor: 'pointer' }} className="small-nav-li">
                        <a href="https://multipad.co/77b74b2ad8f9c55e8e05d25520711cc2.pdf" className="menu-link" target="_blank" rel="noreferrer">One Pager</a>
                    </li>
                    <li style={{ cursor: 'pointer' }} className="small-nav-li">
                        <div>
                            <Button
                                ref={anchorRef}
                                aria-controls={open ? 'menu-list-grow' : undefined}
                                aria-haspopup="true"
                                onClick={handleToggle}
                                // className="menu-link"
                                style={{ color: 'white' }}
                            >
                                {
                                    props.connection.isConnected
                                        ?
                                        <>{props.accoutDetail.address.slice(0, 6)}....{props.accoutDetail.address.slice(-4)}
                                            <br></br>
                                            <span className="networkName">{props.connection.connectedNetwork.name}</span>
                                        </>
                                        : <>
                                            {/* <img src={WalletIcon} alt="walletImg"></img> */}
                                            Connect Wallet</>
                                }
                            </Button>
                            <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
                                {({ TransitionProps, placement }) => (
                                    <Grow
                                        {...TransitionProps}
                                        style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                                    >
                                        <Paper>
                                            <ClickAwayListener onClickAway={handleClose}>
                                                <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                                                    <MenuItem
                                                        onClick={(e) => { handleClose(e); props.onConnectToMetamask(props.networks.main.bsc); expandMenu(); }}>
                                                        <img src={BSCWalletIcon} width="25px" style={{ marginRight: '12px' }}></img> BSC Wallet
                                                    </MenuItem>
                                                    {/* <MenuItem
                                                        onClick={(e) => { handleClose(e); props.onConnectToMetamask(props.networks.main.ethereum, ''); expandMenu(); }}>
                                                        <img src={Ethereum} width="25px" style={{ marginRight: '12px' }}></img> Ethereum
                                                    </MenuItem> */}
                                                    <MenuItem onClick={() => { handleClose; props.onConnectToMetamask(props.networks.main.polygon, '') }}>
                                                        <img src={PolygonIcon} width="25px" style={{ marginRight: '12px' }}></img> Polygon
                                                    </MenuItem>
                                                    {/* <MenuItem
                                                        onClick={() => { handleClose; props.onConnectToMetamask(props.networks.main.avalanche, '') }}>
                                                        <img src={AvalancheIcon} width="25px" style={{ marginRight: '12px' }}></img> Avalanche
                                                    </MenuItem> */}
                                                </MenuList>
                                            </ClickAwayListener>
                                        </Paper>
                                    </Grow>
                                )}
                            </Popper>
                        </div>
                    </li>

                    <li style={{ cursor: 'pointer' }} className="small-nav-li">
                        <a href="https://twitter.com/multi_pad" style={{ margin: '0 10px' }} className="menu-link" target="_blank" rel="noreferrer">
                            <TwitterIcon />
                        </a>
                        <a href="https://telegram.me/multipad_official" style={{ margin: '0 10px' }} className="menu-link" target="_blank" rel="noreferrer">
                            <TelegramIcon />
                        </a>
                        <a href="https://medium.com/@multipad.official" style={{ margin: '0 10px' }} className="menu-link" target="_blank" rel="noreferrer">
                            <img src={MediumIcon} alt="MediumIcon" id="logo" fill="#ffffff" style={{ width: '20px', height: '20px' }} />
                        </a>
                    </li>
                </ul>
            </div>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        accoutDetail: state.reducer.accountDetails || {},
        connection: state.reducer.connection || {},
        networks: state.reducer.networks || {},
        router: state.reducer.router || {}
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onConnectToMetamask: (network, projectNo) => dispatch(connectMetamask(network, projectNo)),
        updateRouter: (val) => dispatch(updateRouter(val))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Navbar);